@font-face {
  font-family: "FSP-BOLD";
  src: local("FSP-BOLD"),
    url(./fonts/FONTSPRING-DEMO-ORGANETTO-SEMIEXPBOLD.OTF) format("opentype");
}
@font-face {
  font-family: "SEMIEXPHAIR";
  src: local("SEMIEXPHAIR"),
    url(./fonts/FONTSPRING-DEMO-ORGANETTO-SEMIEXPHAIR.OTF) format("opentype");
}
@font-face {
  font-family: "SEMIEXPLIGHT";
  src: local("SEMIEXPLIGHT"),
    url(./fonts/FONTSPRING-DEMO-ORGANETTO-SEMIEXPLIGHT.OTF) format("opentype");
}
@font-face {
  font-family: "SEMIEXPREGULAR";
  src: local("SEMIEXPREGULAR"),
    url(./fonts/FONTSPRING-DEMO-ORGANETTO-SEMIEXPREGULAR.OTF) format("opentype");
}
@font-face {
  font-family: "SEMIEXPULTRABOLD";
  src: local("SEMIEXPULTRABOLD"),
    url(./fonts/FONTSPRING-DEMO-ORGANETTO-SEMIEXPULTRABOLD.OTF)
      format("opentype");
}
@font-face {
  font-family: "SEMIEXTBOLD";
  src: local("SEMIEXTBOLD"),
    url(./fonts/FONTSPRING-DEMO-ORGANETTO-SEMIEXTBOLD.OTF) format("opentype");
}
@font-face {
  font-family: "COMFORTAA";
  src: local("COMFORTAA"),
    url(./fonts/COMFORTAA-VARIABLEFONT_WGHT.TTF) format("opentype");
}

.App {
  text-align: center;
}

.ft-1 {
  font-family: "FSP-BOLD";
}

.clr-1 {
  color: #531eaa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 50s linear;
  }
} */

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-shadow {
  display: block;
  width: 100%;
  height: 145px;
  object-fit: fill;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  max-height: 15vh;
}

.header-entranafila {
  object-fit: contain;
}

.container-logo {
  display: flex;
  max-height: 47vh;
  /* margin-top: 76px; */
}

.color-x {
  color: #3c27b3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-x {
  width: 2em;
  height: 2rem;
  accent-color: #3c27b3;
}

.btn-entra-na-fila {
  border-radius: 50px;
  border-width: 0;
  padding: 20px;
  width: 250px;
  font-family: "COMFORTAA";
  font-size: 22px;
  background-image: linear-gradient(
    to left,
    #6b18a0 0%,
    #531eaa 11%,
    #3c27b3 100%
  );
  color: white;
  box-shadow: black 4px 4px 16px 0px;
  margin: 20px;
  cursor: grab;
  justify-content: space-around;
  display: flex;
}

.btn-entra-na-fila:hover {
  border-radius: 50px;
  border-width: 0;
  padding: 20px;
  width: 250px;
  font-family: "COMFORTAA";
  font-size: 25px;
  background-image: linear-gradient(
    to left,
    #3c27b3 0%,
    #531eaa 11%,
    #6b18a0 100%
  );
  color: white;
  box-shadow: black 4px 4px 16px 0px;
  margin: 20px;
  cursor: grab;
}

.btn-entra-na-fila:active {
  border-radius: 50px;
  border-width: 0;
  padding: 20px;
  width: 250px;
  font-family: "COMFORTAA";
  font-size: 25px;
  background-image: linear-gradient(
    to left,
    #3c27b3 0%,
    #531eaa 11%,
    #6b18a0 100%
  );
  color: white;
  box-shadow: black 0px 0px 12px 6px;
  margin: 20px;
  cursor: grabbing;
}

.flex-body {
  height: 100vh;
  overflow-x: hidden;
  background-color: #f2f2f2;
}

.ft-sz-1 {
  font-size: 1em;
}
.ft-sz-2 {
  font-size: 2em;
}
.ft-sz-3 {
  font-size: 3em;
}

.img-profile {
  display: flex;
  border-radius: 50px;
  box-shadow: black 2px 2px 15px 0px;
  object-fit: cover;
  width: 3em;
}

.btn-with-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-border-entra-na-fila {
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  padding: 0px 0px 0px 0px;
  width: 245px;
  font-family: "COMFORTAA";
  font-size: 22px;
  color: white;
  box-shadow: black 4px 4px 16px 0px;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
  border-width: 7px;
  border-style: solid;
  border-image: linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%)
    1;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%);
  background-clip: padding-box, border-box;
}

.label-for-title {
  color: black;
  font-size: 1rem;
  opacity: 0.9;
  width: auto;
  text-align: left;
  padding-left: 28px;
  align-self: flex-start;
  padding-top: 3px;
}

.txt {
  width: inherit;
  padding: 2px;
  margin: 8px 0px 8px 0px;
  font-size: 1.2em;
  outline: none;
  border: none;
  color: black;
  text-align: center;
  background-color: transparent;
}

.spinner {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  padding: 0px 0px 0px 0px;
  width: 245px;
  font-family: "COMFORTAA";
  font-size: 22px;
  box-shadow: black 4px 4px 16px 0px;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
  border-width: 7px;
  border-style: solid;
  border-image: linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%)
    1;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%);
  background-clip: padding-box, border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-body {
  display: flex;
  flex-direction: column;
}
.modal-header {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0px;
  height: 55px;
}

.btn-close-modal {
  background-color: #531eaa;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  border-width: 0px;
  box-shadow: black 2px 2px 4px 0px;
  margin-top: 13px;
  margin-right: 13px;
  position: absolute;
  color: white;
  font-size: medium;
  text-align: center;
  padding: 5px 8px;
}

.btn-close-modal-footer {
  background-color: #531eaa;
  border-radius: 20px;
  border-width: 0px;
  box-shadow: black 2px 2px 4px 0px;
  color: white;
  font-size: medium;
  text-align: center;
  padding: 12px;
  margin: 5px;
}

.modal-title {
  width: 100%;
  height: 100%;
  text-align: center;
}

.modal-content {
  display: flex;
  font-size: 0.8em;
  text-align: center;
  width: 100%;
  min-height: 30vh;
  max-height: 60vh;
  /* height: 40vh; */
  align-items: center;
}

.btn-resend {
  border-radius: 11px;
  border-width: 0;
  padding: 5px;
  width: 168px;
  font-family: "COMFORTAA";
  font-size: 13px;
  background-image: linear-gradient(
    to left,
    #6b18a0 0%,
    #531eaa 11%,
    #3c27b3 100%
  );
  color: white;
  box-shadow: black 4px 4px 16px 0px;
  margin: 8px;
  cursor: grab;
  justify-content: space-around;
  display: flex;
}

.btn-resend:hover {
  border-radius: 11px;
  border-width: 0;
  padding: 5px;
  width: 168px;
  font-family: "COMFORTAA";
  font-size: 14px;
  background-image: linear-gradient(
    to left,
    #3c27b3 0%,
    #531eaa 11%,
    #6b18a0 100%
  );
  color: white;
  box-shadow: black 4px 4px 16px 0px;
  margin: 8px;
  cursor: grab;
}

.btn-resend:active {
  border-radius: 11px;
  border-width: 0;
  padding: 5px;
  width: 168px;
  font-family: "COMFORTAA";
  font-size: 12px;
  background-image: linear-gradient(
    to left,
    #3c27b3 0%,
    #531eaa 11%,
    #6b18a0 100%
  );
  color: white;
  box-shadow: black 0px 0px 12px 6px;
  margin: 8px;
  cursor: grabbing;
}

.lbl-title {
  font-size: 16px;
  width: 100%;
  padding-top: 4px;
  position: absolute;
  text-align: center;
  color: #3c27b3;
}
