.day-week-time-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid;
  border-width: 2px;
  border-radius: 7px;
  padding: 0;
  margin: 4px 8px 4px 8px;
  border-color: #3c27b3;
  box-shadow: #3c27b3 1px 1px 5px 2px;
}

.check-day {
  accent-color: #3c27b3;
  height: 2rem;
  width: 2em;
}

.lbl-day {
  font-size: 16px;
}

.check-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.title-content-hourly {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0px 0px 0px;
  margin: 0px;
}

.time-boxes {
  display: flex;
  flex-direction: row;
  width: 85%;
  align-items: center;
  padding: 4px;
  margin: 0px;
}

.time-box-item {
  max-height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
}

.time-box-item input.MuiInputBase-input.MuiOutlinedInput-input {
  height: 0px !important;
  border: none !important;
  outline: none !important;
}

.check-day {
  accent-color: #3c27b3;
  height: 2rem;
  width: 2em;
  border-width: 2px;
}

.group-time-start-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
