.prof-card {
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  padding: 4px 8px 4px 8px;
  width: 245px;
  font-family: "COMFORTAA";
  font-size: 22px;
  color: white;
  box-shadow: black 4px 4px 16px 0px;
  margin: 4px;
  align-items: center;
  justify-content: space-between;
  border-width: 7px;
  border-style: solid;
  border-image: linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%)
    1;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%);
  background-clip: padding-box, border-box;
}
.lbl1 {
  color: gray;
  width: 100%;
}
.lbl2 {
  color: lightgray;
  font-size: 12px;
  width: 92%;
  display: block;
  text-align: left;
}

.prof-card-options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.btn-prof-option {
  background-color: white;
  color: #3c27b3;
  border-radius: 10px;
  border-width: 2px;
  border: solid;
  border-color: #3c27b3;
}
.btn-prof-option:hover {
  background-color: #3c27b3;
  color: white;
}
.btn-prof-option:active {
  border-width: 3px;
  background-color: white;
  color: #3c27b3;
  box-shadow: #3c27b3 1px 1px 10px 4px;
}
