.day-week-time-box-service {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid;
  border-width: 2px;
  border-radius: 7px;
  padding: 0;
  margin: 4px 8px 4px 8px;
  border-color: #3c27b3;
  box-shadow: #3c27b3 1px 1px 5px 2px;
}

.lbl-day {
  font-size: 16px;
  border-bottom: solid;
  width: 95%;
  border-bottom-color: #3c27b3;
  padding-bottom: 2px;
  padding-top: 2px;
  border-bottom-width: 2px;
}

.check-title-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.title-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0px 0px 0px;
  margin: 0px;
}

.time-boxes-service {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.time-box-item {
  max-height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
}

.time-box-item input.MuiInputBase-input.MuiOutlinedInput-input {
  height: 0px !important;
  border: none !important;
  outline: none !important;
}

.input-modal {
  width: 100%;
  padding: 6px;
  border: solid;
  border-width: 2px;
  border-color: #3c27b3;
  border-radius: 8px;
  font-family: "COMFORTAA";
  font-size: 13px;
  margin: 5px;
  text-align: right;
}

.input-name {
  width: 100%;
  padding: 6px;
  border: none;
  border-width: 2px;
  font-family: "COMFORTAA";
  margin: 0px 5px 5px 5px;
  color: black !important;
  border-bottom: solid;
  border-bottom-color: #3c27b3;
  outline: none;
  text-align: center;
}

.right-btn {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-left: solid;
  border-left-color: #3c27b3;
  border-left-width: 2px;
}
