.main-employees {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.btn-float-new {
  position: absolute;
  right: 5%;
  bottom: 17%;
  width: 40px;
  height: 40px;
  border-width: 6px;
  border-style: solid;
  border-image: linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%)
    1;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #6b18a0 0%, #531eaa 11%, #3c27b3 100%);
  background-clip: padding-box, border-box;
  border-radius: 18px;
  font-size: 24px;
  color: #3c27b3;
  padding: 0px;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #3c27b3 4px 4px 16px 6px;
}

.btn-float-new:hover {
  background-color: gray;
}

.btn-float-new:active {
  border-width: 3px;
  box-shadow: #3c27b3 1px 1px 10px 4px;
}
