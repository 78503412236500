.modal-content-x-absence {
  display: block;
  font-size: 0.8em;
  text-align: center;
  width: 100%;
  min-height: 30vh;
  max-height: 60vh;
  height: 40vh;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.lbl-tb-title {
  padding: 6px;
  border: none;
  border-width: 2px;
  font-family: "COMFORTAA";
  margin: 0px 5px 5px 5px;
  color: black !important;
  border-bottom: solid;
  border-bottom-color: #3c27b3;
  outline: none;
  text-align: center;
}
.table-box {
  border: solid;
  text-align: center;
  font-size: 16px;
  padding: 2px;
  font-family: "COMFORTAA";
  color: #3c27b3;
  border-radius: 7px;
  margin: 4px 8px 4px 8px;
  border-color: #3c27b3;
  box-shadow: #3c27b3 1px 1px 5px 2px;
  margin-top: 8px;
}

.table-calendar {
  width: 100%;
  border-radius: 12px;
  padding: 5px;
  overflow-x: auto;
}

.day-out-of-month {
  color: lightgray !important;
}

/* .table-calendar th {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.table-calendar td {
  width: 100%;
} */

.table-calendar-headers {
  display: flex;
  justify-content: space-evenly;
}

.btn-calendar {
  background-color: white;
  color: #3c27b3;
  border-radius: 10px;
  border-width: 2px;
  border: solid;
  border-color: #3c27b3;
  padding: 0px;
  margin: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn-calendar:hover {
  background-color: #3c27b3;
  color: white;
}

.btn-calendar:active {
  border-width: 3px;
  background-color: white;
  color: #3c27b3;
  box-shadow: #3c27b3 1px 1px 10px 4px;
}

.check-day-lunch {
  accent-color: #3c27b3;
  height: 2rem;
  width: 2em;
  border-width: 2px;
}

.time-boxes-lunch {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.time-box-item {
  max-height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
}

.time-box-item input.MuiInputBase-input.MuiOutlinedInput-input {
  height: 0px !important;
  border: none !important;
  outline: none !important;
}
.input-modal-lunch {
  width: 100%;
  padding: 6px;
  border: solid;
  border-width: 2px;
  border-color: #3c27b3;
  border-radius: 8px;
  font-family: "COMFORTAA";
  font-size: 13px;
  margin: 5px;
  text-align: right;
}

.lbl-day-lunch {
  font-size: 16px;
}

.lunch-box {
  text-align: center;
  font-size: 16px;
  border: solid;
  padding: 2px;
  font-family: "COMFORTAA";
  color: #3c27b3;
  justify-content: center;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-width: 2px;
  border-radius: 7px;
  margin: 4px 8px 4px 8px;
  border-color: #3c27b3;
  box-shadow: #3c27b3 1px 1px 5px 2px;
}

.lunch-controls {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.right-border-lunch {
  height: 18vh;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-left: solid;
  border-left-color: #3c27b3;
  border-left-width: 2px;
  display: flex;
}

.lunch-content {
  display: flex;
  flex-direction: column;
}
