.master-header {
  display: flex;
  align-items: flex-end;
  box-shadow: #3c27b3 2px 0px 22px 4px;
  z-index: 99;
}

.master-title {
  width: 100%;
  display: block;
  position: relative;
  margin: 0;
  text-align: center;
  font-family: "COMFORTAA";
  font-size: 1.6em;
  color: #595959;
  bottom: 0;
}

.master {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  grid-template-rows: 15vh auto 12vh;
}
.master-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2px;
  align-items: center;
  box-shadow: #3c27b3 0px 0px 22px 4px;
}
.master-body {
  display: flex;
  background-color: #f2f2f2;
  height: 100%;
  overflow-y: auto;
}

.btn-home {
  border-radius: 50px !important;
  padding: 8px 25px 14px 25px;
  margin: 2px !important;
  width: auto !important;
  height: 52px !important;
}
.btn-footer {
  background-image: linear-gradient(
    to left,
    #6b18a0 0%,
    #531eaa 11%,
    #3c27b3 100%
  );
  border-radius: 18px;
  border: none;
  box-shadow: black 1px 1px 6px 1px;
  color: lightgray;
  width: 44px;
  height: 44px;
  margin: 5px;
  font-size: 180%;
}
.btn-footer:hover {
  background-image: linear-gradient(to left, #3c27b3 0%, #6b18a0 100%);
  box-shadow: black 2px 2px 8px 2px;
}
.btn-footer:active {
  background-image: linear-gradient(to left, #6b18a0 0%, #3c27b3 100%);
  box-shadow: black 2px 2px 8px 2px;
  font-size: 160%;
}

.active-btn {
  border: solid;
  border-width: 2px;
  border-color: gold;
}
