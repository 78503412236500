.scheduler-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.modal-content-x {
  display: flex;
  font-size: 0.8em;
  text-align: center;
  width: 100%;
  min-height: 30vh;
  max-height: 60vh;
  height: 40vh;
  align-items: center;
}
